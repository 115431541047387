<!--
 * @Description: 项目根组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-04-05 13:14:48
 -->
<template>
	<div id="app" name="app">
		<el-container>
			<!-- 顶部导航栏 -->
			<div class="topbar" v-if="router == -1">
				<div class="nav">
					<ul style="width: 100%;">
						<!-- <el-button type="text" @click="login">登录</el-button> -->
						<li v-if="!this.$store.getters.getUser" style="margin-right: 58%;">
							<!-- <router-link to='/login'>
								<el-button type="text" @click="login">登录</el-button>
							</router-link>

							<span class="sep">|</span>
							<router-link :to="{
									path: '/login',
								}">
								<el-button type="text">注册</el-button> 
							</router-link> -->

						</li>
						<li v-else class="temp" style="margin-right: 54%;">
							<!-- 欢迎
							<el-popover placement="top" width="180" v-model="visible">
								<p>确定退出登录吗？</p>
								<div style="text-align: right; margin: 10px 0 0">
									<el-button size="mini" type="text" @click="visible = false">取消</el-button>
									<el-button type="primary" size="mini" @click="logout">确定</el-button>
								</div>
								<el-button type="text" slot="reference" style="width: 100px;" class="text-1">{{
									this.$store.getters.getUser.UserName
								}}
								</el-button>
							</el-popover> -->
						</li>
						<!-- 	<li style="margin: 0;">
							航鑫源商城<span class="sep">|</span>
						</li> -->
						<li style="margin: 0;">
							<router-link to="/PersonalCenter/Order">我的订单</router-link><span class="sep">|</span>
						</li>
						<router-link to="/shoppingCart">
							<li style="margin: 0;">
								我的购物车<span class="sep">|</span>
							</li>
						</router-link>
						<!-- <router-link to="/enter">
							<li style="margin: 0;">
								实力供应商
							</li>
						</router-link> -->
						<!-- <router-link to="/PersonalCenter/Personal" >
							<li style="margin: 0;">
								个人信息
							</li>
						</router-link> -->
						<!-- <li style="margin: 0;">
							我的优惠卷<span class="sep">|</span>
						</li>
						<li style="margin: 0;">
							我的收藏<span class="sep">|</span>
						</li>
						<li style="margin: 0;">
							客户服务
						</li> -->
						<!-- <li style="margin: 0;color: #666;">
							麦迪恩科技
						</li> -->
						<!-- <li style="margin: 0;">
							<router-link to="/Joinus/MerchantSettled">商家入驻</router-link><span class="sep">|</span>
						</li> -->

						<!-- <li>
							<router-link to="/collect">我的收藏</router-link>
						</li> -->
						<!-- <li :class="getNum > 0 ? 'shopCart-full' : 'shopCart'">
							<router-link to="/shoppingCart">
								<i class="el-icon-shopping-cart-full"></i> 购物车
								<span class="num">({{ getNum }})</span>
							</router-link>
						</li> -->
					</ul>
				</div>
			</div>
			<!-- 顶部导航栏END -->

			<!-- 顶栏容器 -->
			<el-header style="height: 180px;" v-if="router == -1">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
					active-text-color="#409eff" router style="position: relative;height: 164px;">
					<div class="logo clear">
						<router-link to="/">
							<!-- <img :src="getUrl(Config.SiteLogo)" alt style="width: 160px;max-height: 70px;"/> -->
							<img src="./images/logo1.png" alt="" style="width: 138px;height: 130px;" />
						</router-link>
					</div>

					<div class="head1 flexal" v-if="shopRou == -1" style="border: 0;">
						<!-- <div class="head1-cen"> -->
						<!-- <ul class="head1-ul clear"> -->
						<!-- <li class="bgcs" style="cursor: pointer;padding: 0 16px;"> -->
						<!-- <el-link  @click="NavToUrl('/')"> -->
						<div class="bgcs flexcen" style="cursor: pointer;padding: 0 16px;height: 50px;width: 181px;">
							全部商品分类
						</div>

						<!-- </el-link> -->
						<!-- </li> -->
						<!-- <li v-for="(item, index) in NavMenuData" :key="index">
									<el-link :underline="false" @click="NavToUrl('/goods?categoryID=' + item.Id)">{{
											item.Title
									}}</el-link>
									<router-link  :to="item.linkUrl" >{{item.name}}</router-link>
								</li> -->
						<!-- </ul> -->
						<!-- </div> -->
					</div>

					<router-link to="/shoppingCart" v-if="false">
						<div class="car flexal">
							<img src="./images/car.png" alt="" style="width: 20px;height: 20px;margin-right: 8px;" />
							我的购物车
							<div class="num flexcen">{{ getNum }}</div>
						</div>
					</router-link>
					<div class="so">
						<div class="xuan flexal">
							<el-dropdown style="width: 100%;" @command="handleCommand">
								<span class="el-dropdown-link" style="color: #603C1D;">
									{{ temp }}<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="商品">商品</el-dropdown-item>
									<el-dropdown-item command="店铺">店铺</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<el-input placeholder="请输入搜索内容" v-model="search"
							style="height: 33px;display: flex;align-items: center;" class="flexal">
							<el-button style="height: 33px;" slot="append" @click="searchClick">搜索</el-button>
						</el-input>
					</div>
				</el-menu>
			</el-header>
			<!-- 顶栏容器END -->

			<!-- 登录模块 -->
			<MyLogin></MyLogin>
			<!-- 注册模块 -->
			<MyRegister :register="register" @fromChild="isRegister"></MyRegister>

			<!-- 主要区域容器 -->
			<el-main
				:class="[(router != -1) ? 'pads' : '', (router2 != -1) ? 'bgF' : '', shopRou != -1 ? 'pads bgF' : '']">

				<!-- (router == 1)?'pads':'' -->
				<keep-alive>
					<router-view v-if="isRouterAlive" :key="$route.fullPath"></router-view>
				</keep-alive>
			</el-main>
			<!-- 主要区域容器END -->

			<!-- 底栏容器 -->
			<el-footer style="background: #2f2f2f;height: auto;">
				<div class="mod_service_inner" v-if="false">
					<div class="w1224">
						<ul>
							<li>
								<div class="mod_service_unit">
									<h5 class="mod_service_duo">多</h5>
									<p>品类齐全，轻松购物</p>
								</div>
							</li>
							<li>
								<div class="mod_service_unit">
									<h5 class="mod_service_kuai">快</h5>
									<p>多仓直发，极速配送</p>
								</div>
							</li>
							<li>
								<div class="mod_service_unit">
									<h5 class="mod_service_hao">好</h5>
									<p>正品行货，精致服务</p>
								</div>
							</li>
							<li>
								<div class="mod_service_unit">
									<h5 class="mod_service_sheng">省</h5>
									<p>天天低价，畅选无忧</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="footer-ewmcode">
					<div class="foot-list-fl">
						<div class="foot-list-wrap p">
							<ul v-for="(item, index) in FootNavMenuData" :key="index">
								<li class="foot-th">
									{{ item.Name }}
								</li>
								<li v-for="(itclass, classindex) in item.ChildrenList" :key="classindex">
									<router-link :to="{ path: itclass.linkUrl }" @click.native="flushCom">
										{{ itclass.Name }}
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="right-contact-us">
						<h3 class="title">客服热线（9:00-22:00）</h3>
						<span class="phone">19286496723</span>
						<h3 class="title">邮箱</h3>
						<span class="phone">3963549165@qq.com</span>
						<!-- <div class="" style="display: flex;justify-content: space-between;">
							<p class="tips" style="width: 120px;">官方微信</p>
							<p class="tips" style="width: 120px;">小程序</p>
						</div>
						<div style="display: flex;justify-content: space-between;">
							<div class="qr-code-list clearfix">
								<a class="qr-code qr-code-tpshop" href="javascript:;">
									<img src="../src/views/images/erwei.png" alt="" style="width: 120px;height: 120px;" />
								</a>
							</div>

							<div class="qr-code-list clearfix">
								<a class="qr-code qr-code-tpshop" href="javascript:;">
									<img src="./views/images/xiaochengxu.png" alt="" style="width: 120px;height: 120px;" />
								</a>
							</div>
						</div> -->
					</div>
					<div>
						<div class="" style="display: flex;justify-content: space-between;color: #fff;">
							<!-- <p class="tips" style="width: 120px;">官方微信</p> -->
							<p class="tips" style="width: 120px;">小程序</p>
						</div>
						<div style="display: flex;justify-content: space-between;">
							<!-- <div class="qr-code-list clearfix" style="margin-right: 16px;">								
								<a class="qr-code qr-code-tpshop" href="javascript:;">
									<img src="../src/views/images/erwei.png" alt="" style="width: 120px;height: 120px;" />
								</a>
							</div> -->
							<div class="qr-code-list clearfix">
								<!--<a class="qr-code" href="javascript:;"><img src="/template/pc/rainbow/static/images/qrcode.png" alt="" /></a>-->
								<a class="qr-code qr-code-tpshop" href="javascript:;">
									<img src="/static/img/xiaochengxu.jpg" alt="" style="width: 120px;height: 120px;" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="mod_help" v-if="false">
						<p>
							<router-link to="/">首页</router-link>
							<span style="padding:0 10px">|</span>
							<span v-for="(item, index) in NavMenuData" :key="index" style="padding:0">
								<router-link :to="item.linkUrl">{{ item.name }}</router-link>
								<span style="padding:0 10px" v-if="NavMenuData.length - 1 != index">|</span>
							</span>
						</p>
					</div>
					<div class="btmInfo">
						<!-- <div>
							<span class="sep"></span>
							<a :href="$target + '/服务协议.docx'">服务协议</a>
						</div> -->
						<!--<div>
							<span class="sep">|</span>
							<a href="#">用户协议</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">用户反馈</a>
						</div>-->
						<!-- <div>
							<span class="sep">|</span>
							<a :href="$target + '/隐私条款.docx'">隐私政策</a>
						</div> -->

						<!--<div>
							<span class="sep">|</span>
							<a href="#">销售条款</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">网站申明</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">购物流程</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">服务热线</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">购物指南</a>
						</div>
						<div>
							<span class="sep">|</span>
							<a href="#">售后服务</a>
						</div> -->
						<div v-if="Config.webcrod">
							<!-- <span class="sep">|</span> -->
							<a href="https://beian.miit.gov.cn">{{ Config.webcrod }}</a>
						</div>
					</div>
					<p class="coty">
						{{
							Config.copyright
							? Config.copyright
							: "深圳市航鑫源化妆品有限公司 © 2023～2024 All rights reserved "
						}}
					</p>
					<!-- <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
						style="color: #fff;">粤ICP备2023050039号-1</a> -->
				</div>
			</el-footer>
			<!-- 底栏容器END -->
		</el-container>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	import {
		mapGetters
	} from "vuex";

	export default {
		provide() {
			return {
				reload: this.reload,
			};
		},
		beforeUpdate() {
			this.activeIndex = this.$route.path;
			this.router = this.$route.path.indexOf('login')
			console.log(this.$route.path)
			if (this.router == -1) {
				this.router = this.$route.path.indexOf('register')
			}
			if (this.$route.path.indexOf('shoppingCart') == -1 || this.$route.path.indexOf('goods/details') == -1) {
				this.router2 = 1
			}
			this.shopRou = this.$route.path.indexOf('shop')
			if (this.shopRou == -1) {
				this.shopRou = this.$route.path.indexOf('/PersonalCenter/Personal')
			}
			if (this.$route.path.indexOf('login') == -1) {
				this.router = this.$route.path.indexOf('/enter')
				console.log(this.router)
			}
		},
		data() {
			return {
				activeIndex: "", // 头部导航栏选中的标签
				search: "", // 搜索条件
				register: false, // 是否显示注册组件
				visible: false, // 是否退出登录
				NavMenuData: [],
				Config: {},
				isRouterAlive: true,
				dropdown: true,
				temp: "商品",
				router: 1,
				router2: 1,
				shopRou: 1
			};
		},
		created() {
			// 获取浏览器localStorage，判断用户是否已经登录
			if (localStorage.getItem("userToken")) {
				// 如果已经登录，设置vuex登录状态
				//JSON.parse(localStorage.getItem("user"))
				this.GetUserInfo();
			}
			this.loadNavMenuList();
			this.Getconfig();
			this.loadFootNavMenuList()
		},
		computed: {
			...mapGetters(["getUser", "getNum"]),
		},
		watch: {
			// 获取vuex的登录状态
			getUser: async function(val) {
				if (val === "") {
					// 用户没有登录
					this.setShoppingCart([]);
				} else {
					// 用户已经登录,获取该用户的购物车信息
					const res = await this.postdata("/Goods/CartList");
					if (res.code == 200) {
						// let list = []
						// for (let index = 0; index < res.data.items.length; index++) {
						// 	const element = res.data.items[index].list;
						// 	list = list.concat(element)
						// }
						let list = res.data.items
						console.log(list, 'list')
						list.filter(v => {
							v.check = false
						})
						this.setShoppingCart(list);
					} else {
						// 提示失败信息
						this.notifyError(res.msg);
					}
				}
			},
		},
		methods: {
			...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
			login() {
				// 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
				// this.setShowLogin(true);

			},
			// 退出登录
			logout() {
				this.visible = false;
				// 清空本地登录信息
				localStorage.setItem("userToken", "");
				// 清空vuex登录信息
				this.setUser("");
				this.notifySucceed("成功退出登录");
			},
			// 接收注册子组件传过来的数据
			isRegister(val) {
				this.register = val;
			},
			// 点击搜索按钮
			searchClick() {
				if (this.search != "") {
					// 跳转到全部商品页面,并传递搜索条件
					if (this.temp == "商品") {
						this.$router.push({
							path: "/goods",
							query: {
								search: this.search,
							},
						});
						this.search = "";
					} else {
						this.$router.push({
							path: "/shopList",
							query: {
								search: this.search,
							},
						});
						this.search = "";
					}

				}
			},
			async Getconfig() {
				var res = await this.postdata("/News/GetConfig", {
					queryType: ''
				});
				if (res.code == 200) {
					this.Config = res.data;
				}
			},

			async loadNavMenuList() {
				var res = await this.postdata("/News/ClassList", {
					page: 1,
					limit: 10,
				});
				if (res.code == 200) {
					this.NavMenuData = res.data.items;
				}
			},
			async GetUserInfo() {
				const res = await this.postdata("/User/GetUser");
				if (res.code == 200) {
					this.setUser(res.data);
				}
			},
			NavToUrl(url) {
				var query = this.getUrlParam(url);
				var path = url.split("?")[0];
				this.$router.push({
					path: path,
					query: query,
				});
			},
			getUrlParam(url) {
				let obj = {};
				let p = url.split("?")[1];
				if (p) {
					let keyValue = p.split("&");
					for (let i = 0; i < keyValue.length; i++) {
						let item = keyValue[i].split("=");
						let key = item[0];
						let value = item[1];
						obj[key] = value;
					}
				}
				return obj;
			},
			flushCom() {
				// this.$router.go(0)
			},
			reload() {
				console.log("reload", 301);
				this.isRouterAlive = false;
				this.$nextTick(() => {
					this.isRouterAlive = true;
				});
			},
			open() {
				this.dropdown = true;
			},
			handleCommand(command) {
				console.log(command);
				this.temp = command;
			},
		},
	};
</script>

<style scoped>
	.bgF {
		background-color: #fff !important;
	}

	.btmInfo {
		display: flex;
		justify-content: center;
	}

	.btmInfo a {
		color: #b6b6b6;
		font-size: 12px;
	}

	.btmInfo .sep {
		color: #b6b6b6;
		padding: 0 10px;
	}

	.btmInfo a:hover {
		color: #fff;
	}
</style>

<style>
	em {
		font-style: normal;
	}

	.clearfix {
		clear: both;
	}

	.redxx {
		color: #ff0000;
	}

	/* 全局CSS */
	.box-bd {
		min-height: 615px;
	}

	* {
		padding: 0;
		margin: 0;
		border: 0;
		list-style: none;
	}

	.clear::before {
		content: "";
		clear: both;
	}

	#app .el-header {
		/* margin: 16px 0 0; */
		padding-top: 16px;
	}

	#app .el-main {
		min-height: 300px;
		/* padding: 20px; */
		background-color: #f5f5f5;
		/* border: 2px solid #967D67; */
	}

	#app .el-footer {
		padding: 0;
	}

	.pads {
		padding: 0 !important;
	}

	a,
	a:hover {
		text-decoration: none;
	}

	/* 全局CSS END */

	/* 顶部导航栏CSS */
	.topbar {
		height: 30px;
		background-color: #ED5D29;
	}

	.topbar .nav {
		width: 1440px;
		margin: 0 auto;
	}

	.topbar .nav ul {
		float: right;
	}

	.topbar .nav li {
		float: left;
		height: 30px;
		color: #fff;
		font-size: 12px;
		text-align: center;
		line-height: 30px;
		margin-left: 20px;
		cursor: pointer;
	}

	.topbar .nav .sep {
		color: #b0b0b0;
		font-size: 12px;
		margin: 0 5px;
	}

	.topbar .nav li .el-button {
		color: #fff;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.topbar .nav .el-button:hover {
		color: #ff0000;
	}

	.topbar .nav li a {
		color: #fff;
	}

	.topbar .nav a:hover {
		color: #ff0000;
	}

	.topbar .nav .shopCart {
		width: 120px;
		background: #424242;
	}

	.topbar .nav .shopCart:hover {
		background: #fff;
	}

	.topbar .nav .shopCart:hover a {
		color: #ff6700;
	}

	.topbar .nav .shopCart-full {
		width: 120px;
		background: #ff6700;
	}

	.topbar .nav .shopCart-full a {
		color: white;
	}

	/* 顶部导航栏CSS END */

	/* 顶栏容器CSS */
	.el-header .el-menu {
		max-width: 1440px;
		margin: 0 auto;
	}

	.el-menu.el-menu--horizontal {
		border: none !important;
	}

	.el-header .logo {
		height: 130px;
		width: 189px;
		float: left;
		margin-right: 100px;
	}

	.el-header .so {
		margin-top: 35px;
		width: 553px;
		/* float: right; */
		height: 48px;
		display: flex;
		margin-left: 186px;
	}

	.head1 {
		margin: auto;
		width: 1440px;
		position: absolute;
		bottom: -20px;
		left: 0;
	}

	.head1>.head1-left {
		float: left;
	}

	.head1>.head1-left img {
		display: block;
		width: 60px;
		height: 60px;
		margin-top: 20px;
	}

	.head1 .head1-cen {
		/* margin-left: 187px; */
		background-color: beige;
	}

	.head1 .head1-cen li {
		list-style: none;
		float: left;
		height: 50px;
		line-height: 50px;
		width: 181px;
	}

	.head1 .head1-cen li:hover>.head2 {
		display: block;
		transition: 0.8s;
	}

	.head1 .head1-cen li a {
		font-size: 16px;
		color: #e23435;
		text-decoration: none;
		padding: 0px 10px;
	}

	.head1 .head1-cen li a:hover {
		color: #fff;
		background: #ED5D29;
	}

	.bgcs,
	.head1 .head1-cen .bgcs a {
		background: #ED5D29;
		color: #fff;
	}

	.head1 .head1-right {
		float: right;
		line-height: 100px;
	}

	.head1 .head1-right input {
		width: 245px;
		height: 50px;
		padding-left: 10px;
		color: #797979;
		border: 0.5px solid #797979;
	}

	.head1 .head1-right>span {
		float: right;
		display: block;
		width: 52px;
		height: 50px;
		margin-top: 25.5px;
		border: 0.5px solid #797979;
		line-height: 50px;
		text-align: center;
		font-size: 25px;
		cursor: pointer;
	}

	.head1 .head1-right>span:hover {
		background-color: #fe5d28;
		color: aliceblue;
	}

	.head2 {
		width: 100%;
		left: 0;
		display: none;
		height: 250px;
		position: absolute;
		background-color: #ffffff;
		box-shadow: 0 3px 3px #8e8e8e;
		z-index: 80;
	}

	.head2>hr {
		border: 0.2px solid #d4d4d4;
	}

	.head2>div {
		transform: translate(-50%);
		left: 50%;
		width: 1226px;
		height: 247px;
		position: absolute;
	}

	.head2>div>a {
		width: 15%;
		height: 250px;
		text-align: center;
		float: left;
		text-decoration: none;
	}

	.head2>div>a>img {
		width: 90%;
		height: 40%;
		margin-top: 40px;
		border-right: 1px solid #a4a4a4;
	}

	.head2>div>a>h3 {
		font-size: 15px;
		line-height: 30px;
		color: #424242;
		font-weight: normal;
	}

	.head2>div>a>h4 {
		line-height: 24px;
		font-size: 12px;
		color: #ff6700;
		font-weight: normal;
	}

	/* 顶栏容器CSS END */

	/* 底栏容器CSS */
	.footer {
		width: 100%;
		text-align: center;
		background: #2f2f2f;
		padding-bottom: 20px;
	}

	.footer .ng-promise-box {
		border-bottom: 1px solid #3d3d3d;
		line-height: 145px;
	}

	.footer .ng-promise-box {
		margin: 0 auto;
		border-bottom: 1px solid #3d3d3d;
		line-height: 145px;
	}

	.footer .ng-promise-box .ng-promise p a {
		color: #fff;
		font-size: 20px;
		margin-right: 210px;
		padding-left: 44px;
		height: 40px;
		display: inline-block;
		line-height: 40px;
		text-decoration: none;
		background: url("./assets/imgs/us-icon.png") no-repeat left 0;
	}

	.footer .github {
		height: 50px;
		line-height: 50px;
		margin-top: 20px;
	}

	.footer .github .github-but {
		width: 50px;
		height: 50px;
		margin: 0 auto;
		background: url("./assets/imgs/github.png") no-repeat;
	}

	.footer .mod_help {
		text-align: center;
		color: #888888;
	}

	.footer .mod_help p {
		padding: 20px 0 16px 0;
	}

	.footer .mod_help p a {
		color: #888888;
		text-decoration: none;
	}

	.footer .mod_help p a:hover {
		color: #fff;
	}

	.footer .mod_help p span {
		padding: 0 22px;
	}

	.footer .coty {
		margin-top: 24px;
		color: #888888;
	}

	.HminMoney {
		font-size: 14px;
		color: #000 !important;
	}

	.HMoney {
		font-size: 20px;
		color: #000 !important;
	}

	.HrightMoney {
		font-size: 16px;
		color: #000 !important;
	}

	/* 底栏容器CSS END */
	.text-1 {
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
	}

	.car {
		width: 178px;
		height: 33px;
		background: #ffffff;
		border-radius: 0px 0px 0px 0px;
		border: 1px solid #d2d2d2;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		color: #E23435;
		float: right;
		margin-top: 35px;
		margin-left: 160px;
		cursor: pointer;
	}

	.el-header .el-input__inner {
		height: 33px !important;
		width: 336px !important;
		border: 1px solid #ED5D29;
		border: 2px solid #ED5D29 !important;
		margin-right: 0 !important;
		border-radius: 0 !important;
	}

	.el-input-group__append .el-button {
		width: 132px;
		height: 33px;
		background: #ED5D29 !important;
		flex: 1;
		color: #fff !important;
		font-size: 24px;
		/* margin-right: 0 !important; */
		box-sizing: border-box !important;
	}

	.el-input .el-input-group__append {
		padding: 0 !important;
		border-radius: 0 !important;
	}

	.flexal {
		display: flex;
		align-items: center;
	}

	.flexcen {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flexspb {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flexcum {
		display: flex;
		flex-direction: column;
	}

	.fal {
		align-items: stretch;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #409eff;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.el-header .xuan {
		width: 85px;
		height: 33px;
		border: 2px solid #ED5D29;
		/* display: inline-block; */
		border-right: 0;
		flex-shrink: 0;
		box-sizing: border-box;
		padding-left: 12px;
	}

	.el-header .num {
		width: 18px;
		height: 18px;
		margin-left: 6px;
		border-radius: 50%;
		background-color: #ff0000;
		color: #fff;
		font-size: 12px;
	}

	.mod_service_inner {
		padding: 30px 0;
		border-bottom: 1px solid #ddd;
		display: flex;
		justify-content: center;
	}

	.mod_service_inner ul {
		overflow: hidden;
		height: 42px;
	}

	.mod_service_inner ul li {
		float: left;
		width: 305px;
	}

	.mod_service_inner ul li+li {
		border-left: 1px solid #ddd;
	}

	.mod_service_inner ul li .mod_service_unit {
		position: relative;
		margin: 0 auto;
		padding-left: 45px;
		width: 180px;
	}

	.mod_service_inner ul li .mod_service_unit h5 {
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		width: 36px;
		height: 42px;
		text-indent: -999px;
		background-image: url(./images/ico_service.png);
	}

	.mod_service_inner ul li .mod_service_unit p {
		overflow: hidden;
		width: 100%;
		height: 42px;
		line-height: 42px;
		font-size: 20px;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #333;
	}

	.mod_service_duo {
		background-position: 0 0;
	}

	.mod_service_kuai {
		background-position: 0 -43px;
	}

	.mod_service_hao {
		background-position: 0 -86px;
	}

	.mod_service_sheng {
		background-position: 0 -129px;
	}

	.footer-ewmcode {
		overflow: hidden;
		padding: 20px 0;
		display: flex;
		justify-content: center;
	}

	.foot-list-fl {
		float: left;
		width: 940px
	}

	.foot-list-fl ul {
		float: left;
		width: 188px
	}

	.foot-list-fl ul li {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-left: 20px;
		margin-bottom: 8px;
	}

	.foot-list-fl ul li.foot-th {
		font-size: 20px;
		padding-bottom: 4px;
		color: #fff;
		font-weight: 700;
	}

	.foot-list-fl ul li a {
		color: #fff;
		font-size: 16px;
	}

	.right-contact-us {
		float: right;
		width: 250px;
		padding-left: 20px;
	}

	.right-contact-us .title {
		font-weight: normal;
		height: 30px;
		line-height: 30px;
		font-size: 18px;
		color: #fff;
	}

	.right-contact-us .phone {
		display: block;
		height: 35px;
		line-height: 35px;
		font-size: 18px;
		color: #fff;
	}

	.right-contact-us .tips {
		margin-bottom: 5px;
		line-height: 20px;
		color: #fff;
		font-size: 14px;
	}

	.padNo {
		padding: 0;
	}

	.el-button--default {
		margin: 0 !important;
		border-radius: 0 !important;
		width: 96px !important;
		font-size: 18px !important;
		line-height: 37px !important;
		padding: 0 !important;

	}
</style>